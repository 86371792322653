import Vue from "vue";
import Vuex from "vuex";
import messenger from "../store/messenger";
import conversation from "../store/conversation";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    messenger: {
      namespaced: true,
      ...messenger
    },
    conversation: {
      namespaced: true,
      ...conversation
    }
  }
});

export default store;
