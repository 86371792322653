import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
// import "./plugins/bootstrap-vue";
import "./plugins/eventBus";
import "./plugins/thirdparty";
import "./plugins/axios";
import globalMixin from "./mixins/global";
import notification from "./mixins/pushnotification";
import App from "./App.vue";
import store from "./store";
import vueCustomElement from "vue-custom-element";
import "./stylesheets/app.css";

Vue.use(vueCustomElement);
Vue.config.productionTip = false;
Vue.mixin(globalMixin);
Vue.mixin(notification);

App.store = store;
Vue.customElement("qontak-webchat", App);
