import axios from "axios";
import CountryCode from "./../../country-code.json";
import { UPLOAD_URL } from "../plugins/endpoint";
import { convertDate } from "../utils/helper";

export default {
  version: "1.0.13",
  state: {
    isValidated: false,
    user: null,
    display: {
      emoji: false,
      attachmentLists: false
    },
    message: {
      type: "text",
      content: ""
    },
    fileAttachments: [],
    countryCodeLists: [],
    isDirectSignIn: false,
    floatingNotification: {
      text: "Welcome!",
      icon: "",
      shown: false
    },
    styles: {
      generalButton: {
        "background-color": "#0274f5",
        color: "#ffffff"
      },
      floatingButton: {
        "background-color": "#0274f5",
        "border-radius": "50%",
        height: "60px",
        width: "60px"
      },
      headerPanel: {
        form: {
          content: {
            title: "Hello! We would love to talk to you.",
            description:
              "Please fill the details below to start chatting with us"
          },
          style: {
            panel: {
              "background-color": "#0274f5"
            },
            title: {
              color: "#FFFFFF",
              "font-size": "24px"
            },
            description: {
              color: "#FFFFFF",
              "font-size": "14px"
            }
          }
        },
        conversation: {
          content: {
            online: "",
            offline: "",
            default:
              "Need help? Reach out to us right here, and we'll get back to you as soon as we can!"
          },
          style: {
            panel: {
              "background-color": "#0274f5"
            },
            title: {
              color: "#FFFFFF",
              "font-size": "13px"
            }
          }
        }
      },
      conversation: {
        divider: {
          color: "#687992",
          lineColor: "#687992"
        },
        bubbleChat: {
          left: {
            mainColor: "#fff",
            textColor: "#2d3d54",
            shadow: "#a8c3c7"
          },
          right: {
            mainColor: "#f6f9ff",
            textColor: "#2d3d54",
            shadow: "#a8c3c7"
          }
        }
      },
      greeting: {
        is_active: false,
        greetingMsg: "Have questions? We're here to help.",
        position: "top"
      },
      logo: {
        is_active: false,
        url: ""
      }
    }
  },
  actions: {
    validateWebchat({ commit }) {
      return new Promise((resolve, reject) => {
        const instance = axios.create({
          baseURL: decodeURIComponent(process.env.VUE_APP_SERVICE_URL)
        });
        instance
          .post(
            `/webhooks/v1/webchat/validate/${localStorage.getItem(
              "qchat_code"
            )}`,
            {
              widget_domain: localStorage.getItem("qchat_domain")
            }
          )
          .then(res => {
            commit("UPDATE_STYLE", res.data.response.settings?.style);
            commit(
              "UPDATE_CUSTOM_WORDING",
              res.data.response.settings ? res.data.response.settings : null
            );
            commit(
              "UPDATE_GREETING_BOX",
              res.data.response.settings
                ? res.data.response.settings?.widget_text
                : null
            );
            commit("UPDATE_LOGO", res.data.response);
            resolve(res.status !== "error");
            commit("UPDATE_IS_VALIDATED", res.status !== "error");
          })
          .catch(() => {
            reject(false);
          });
      });
    },
    getCountryCode({ commit }) {
      commit("UPDATE_COUNTRY_CODE", CountryCode);
    },
    registerWebchat({ commit }, params) {
      return new Promise((resolve, reject) => {
        const userData = {
          ...params,
          action: `start_conversation`,
          code: localStorage.getItem("qchat_code"),
          widget_id: localStorage.getItem("qchat_id"),
          resource_type: `web`,
          ext_id: localStorage.getItem("qchat_ext_id") || null
        };

        const instance = axios.create({
          baseURL: decodeURIComponent(process.env.VUE_APP_SERVICE_URL)
        });

        instance
          .post(
            `/webhooks/v1/webchat/${userData.code}/widget_id/${userData.widget_id}`,
            {
              ...userData,
              widget_domain: localStorage.getItem("qchat_domain")
            }
          )
          .then(res => {
            if (res.data.status && !res.data.room) {
              commit("SHOW_NOTIFICATION", {
                text: res.data.status?.includes("contact is blocked")
                  ? "Your contact has been blocked"
                  : res.data.status,
                icon: "info-circle",
                timeout: 5000
              });
              reject(false);
            } else {
              commit("UPDATE_USER", {
                ...userData,
                id: "USER_" + convertDate(),
                ...res.data.room
              });
              commit("conversation/UPDATE_CONVERSATIONS", res.data.messages, {
                root: true
              });
              localStorage.setItem(
                "qchat_user",
                JSON.stringify({
                  email: params.email,
                  name: params.name,
                  token: res.data.user_credential.token,
                  widget_id: localStorage.getItem("qchat_id"),
                  phone_number: params.phone_number
                })
              );
              localStorage.setItem("qchat_room_id", res.data.room.id);
              resolve(true);
            }
          })
          .catch(err => {
            commit("SHOW_NOTIFICATION", {
              text: "Failed to auto sign in. Please sign in manually",
              icon: "info-circle",
              timeout: 5000
            });
            // Regenerate external ID when failed to register and when qchat_ext_id is null
            if (!localStorage.getItem("qchat_ext_id")) {
              const id = localStorage.getItem("qchat_id");
              const code = localStorage.getItem("qchat_code");
              const date = new Date();
              const extID = btoa(`${id}_${code}_${date.getTime()}`);
              localStorage.setItem("qchat_ext_id", extID);
            }
            reject(err);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    attachmentUpload({ state }, params) {
      return new Promise((resolve, reject) => {
        const myHeaders = new Headers();
        const formdata = new FormData();

        formdata.append("webhook", localStorage.getItem("qchat_code"));
        formdata.append("file", params.file.file);
        formdata.append("widget_domain", localStorage.getItem("qchat_domain"));

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow"
        };

        fetch(`${UPLOAD_URL}`, requestOptions)
          .then(data => data.json())
          .then(res => {
            params.file.url = res.data.file.url;
            params.file.status = "success";
            params.file.loading = 100;
            params.file.filename = res.data.file.filename;
            resolve(params.file);
          })
          .catch(() => {
            params.file.url = null;
            params.file.status = "failed";
            params.file.loading = 100;
            params.file.filename = params.file.file.name;
            reject(params);
          });
      });
    },
    getConversation({ commit }, params) {
      return new Promise((resolve, reject) => {
        const userData = {
          ...params,
          action: `start_conversation`,
          code: localStorage.getItem("qchat_code"),
          widget_id: localStorage.getItem("qchat_id"),
          resource_type: `web`,
          ext_id: localStorage.getItem("qchat_ext_id") || null
        };

        const roomId = localStorage.getItem("qchat_room_id");

        const instance = axios.create({
          baseURL: decodeURIComponent(process.env.VUE_APP_SERVICE_URL)
        });

        instance
          .post(`/api/core/v1/messages/webchat/${userData.code}/${roomId}`, {
            widget_domain: localStorage.getItem("qchat_domain")
          })
          .then(res => {
            if (res.data.status && !res.data.data.room) {
              commit("SHOW_NOTIFICATION", {
                text: res.data.status?.includes("contact is blocked")
                  ? "Your contact has been blocked"
                  : res.data.status,
                icon: "info-circle",
                timeout: 5000
              });
              reject(false);
            } else {
              commit("UPDATE_USER", {
                ...userData,
                id: "USER_" + convertDate(),
                ...res.data.data.room
              });
              commit(
                "conversation/UPDATE_CONVERSATIONS",
                res.data.data.messages,
                {
                  root: true
                }
              );
              resolve(true);
            }
          })
          .catch(err => {
            commit("SHOW_NOTIFICATION", {
              text: "Failed to auto sign in. Please sign in manually",
              icon: "info-circle",
              timeout: 5000
            });
            // Regenerate external ID when failed to register and when qchat_ext_id is null
            if (!localStorage.getItem("qchat_ext_id")) {
              const id = localStorage.getItem("qchat_id");
              const code = localStorage.getItem("qchat_code");
              const date = new Date();
              const extID = btoa(`${id}_${code}_${date.getTime()}`);
              localStorage.setItem("qchat_ext_id", extID);
            }
            reject(err);
          });
      });
    }
  },
  mutations: {
    UPDATE_DISPLAY(state, payload) {
      state.display[payload] = !state.display[payload];
    },
    UPDATE_MESSAGE_INPUT(state, payload) {
      state.message.content = payload;
    },
    UPDATE_USER(state, payload) {
      state.user = payload;
      state.isDirectSignIn = false;
    },
    UPDATE_COUNTRY_CODE(state, payload) {
      state.countryCodeLists = payload;
    },
    SET_IMAGE_TO_ATTACHMENT_LIST(state, payload) {
      state.fileAttachments.push(payload);
    },
    UPDATE_ATTACHMENT_BY_INDEX(state, payload) {
      const idx = state.fileAttachments.findIndex(
        data => data.id === payload.id
      );
      state.fileAttachments[idx] = payload;
    },
    DELETE_ATTACHMENT(state, payload) {
      state.fileAttachments.splice(payload, 1);
    },
    DELETE_ALL_ATTACHMENT(state) {
      state.fileAttachments = [];
    },
    DIRECT_SIGN_IN_UPDATE(state, payload) {
      state.isDirectSignIn = payload;
    },
    SHOW_NOTIFICATION(state, payload) {
      state.floatingNotification = {
        text: payload.text,
        icon: payload.icon,
        shown: true
      };
      if (payload.timeout)
        setTimeout(() => {
          state.floatingNotification.shown = false;
        }, payload.timeout);
    },
    HIDE_NOTIFICATION(state) {
      state.floatingNotification = {
        text: "",
        icon: "",
        shown: false
      };
    },
    REFRESH_ROOM_EXPIRATION(state) {
      state.user.session_at = new Date();
    },
    UPDATE_STYLE(state, payload) {
      const additionalHeader = payload?.header
        ? {
            "background-color": payload.header.bg_color || "#0274f5",
            color: payload.header.text_color || "#ffffff"
          }
        : {};

      const additionalAgentMsg = payload?.agent_message
        ? {
            mainColor: payload.agent_message.bg_color || "#ffffff",
            textColor: payload.agent_message.text_color || "#2d3d54"
          }
        : {};

      const additionalVisitorMsg = payload?.visitor_message
        ? {
            mainColor: payload.visitor_message.bg_color || "#f6f9ff",
            textColor: payload.visitor_message.text_color || "#2d3d54"
          }
        : {};

      const newStyle = {
        ...state.styles,
        generalButton: {
          ...state.styles.generalButton,
          ...additionalHeader
        },
        floatingButton: {
          ...state.styles.floatingButton,
          ...additionalHeader
        },
        headerPanel: {
          ...state.styles.headerPanel,
          form: {
            ...state.styles.headerPanel.form,
            style: {
              ...state.styles.headerPanel.form.style,
              panel: {
                ...state.styles.headerPanel.form.style.panel,
                "background-color": payload?.header?.bg_color || "#0274f5"
              },
              title: {
                ...state.styles.headerPanel.form.style.title,
                color: payload?.header?.text_color || "#ffffff"
              },
              description: {
                ...state.styles.headerPanel.form.style.description,
                color: payload?.header?.text_color || "#ffffff"
              }
            }
          },
          conversation: {
            ...state.styles.headerPanel.conversation,
            style: {
              panel: {
                ...state.styles.headerPanel.conversation.style.panel,
                "background-color": payload?.header?.bg_color || "#0274f5"
              },
              title: {
                ...state.styles.headerPanel.conversation.style.title,
                color: payload?.header?.text_color || "#ffffff"
              }
            }
          }
        },
        conversation: {
          ...state.styles.conversation,
          bubbleChat: {
            ...state.styles.conversation.bubbleChat,
            left: {
              ...state.styles.conversation.bubbleChat.left,
              ...additionalAgentMsg
            },
            right: {
              ...state.styles.conversation.bubbleChat.right,
              ...additionalVisitorMsg
            }
          }
        }
      };
      state.styles = newStyle;
    },
    UPDATE_WORDING(state, payload) {
      const headerContent = payload.pre_chat
        ? {
            title: payload.pre_chat.title,
            description: payload.pre_chat.subtitle
          }
        : {};

      const whenOnlineContent = payload.when_online
        ? {
            content: payload.when_online.title
          }
        : {};

      const newContent = {
        ...state.styles,
        headerPanel: {
          ...state.styles.headerPanel,
          form: {
            ...state.styles.headerPanel.form,
            content: {
              ...state.styles.headerPanel.form.content,
              ...headerContent
            }
          },
          conversation: {
            ...state.styles.headerPanel.conversation,
            ...whenOnlineContent
          }
        }
      };
      state.styles = newContent;
    },
    UPDATE_IS_VALIDATED(state, params) {
      state.isValidated = params;
    },
    UPDATE_CUSTOM_WORDING(state, params) {
      if (params) {
        if (params.pre_chat?.title)
          state.styles.headerPanel.form.content.title = params.pre_chat.title;
        if (params.pre_chat?.subtitle)
          state.styles.headerPanel.form.content.description =
            params.pre_chat.subtitle;
        if (params.when_online?.title)
          state.styles.headerPanel.conversation.content.online =
            params.when_online.title;
        if (params.when_offline?.title)
          state.styles.headerPanel.conversation.content.offline =
            params.when_offline.title;
      }
    },
    UPDATE_AGENT_STATUS(state, params) {
      state.user.extra.is_participant_online = params;
    },
    UPDATE_GREETING_BOX(state, params) {
      if (params) {
        state.styles.greeting.is_active = params.is_active;
        if (params.greeting)
          state.styles.greeting.greetingMsg = params.greeting;
        if (params.position) state.styles.greeting.position = params.position;
      }
    },
    UPDATE_LOGO(state, params) {
      state.styles.logo.is_active = params.settings.widget_logo.is_active;
      state.styles.logo.url = params.logo.url;
    }
  },
  modules: {}
};
