import axios from "axios";
import { convertDate } from "../utils/helper";

export default {
  state: {
    conversations: [],
    token: "bearer sm273g5Mwrh6W1sIKG9-vmomo2ZjxSlImUsdKd55E8Y"
  },
  actions: {
    sendMessage({ commit }, params) {
      return new Promise((resolve, reject) => {
        // Set temporary ID with current date & time
        let tempId = "MESSAGE_" + convertDate();
        tempId += new Date().getMilliseconds();

        // Update & formatting conversation with backend's response format
        commit("UPDATE_LOCAL_CONVERSATION", {
          payload: params.payload,
          file: params.file,
          id: tempId,
          user: {
            id: params.user.id,
            name: params.user.name
          }
        });

        const instance = axios.create({
          baseURL: decodeURIComponent(process.env.VUE_APP_SERVICE_URL)
        });

        instance
          .post(
            `/webhooks/v1/webchat/${params.user.code}/widget_id/${params.user.widget_id}`,
            {
              ...params.payload,
              account_uniq_id: params.user.email,
              action: `send_message`,
              widget_domain: localStorage.getItem("qchat_domain"),
              ext_id: localStorage.getItem("qchat_ext_id")
            }
          )
          .then(res => {
            if (localStorage.getItem("qchat_room_id") !== res.data.room_id) {
              localStorage.setItem("qchat_room_id", res.data.room_id);
            }
            // Update status to "delivered"
            commit("UPDATE_CONVERSATION_STATUS", {
              id: tempId,
              status: "delivered"
            });
            commit("messenger/REFRESH_ROOM_EXPIRATION", {}, { root: true });
            resolve(true);
          })
          .catch(err => {
            // Update status to "failed"
            commit("UPDATE_CONVERSATION_STATUS", {
              id: tempId,
              status: "failed"
            });
            commit(
              "messenger/SHOW_NOTIFICATION",
              {
                text:
                  err.response.data?.error?.messages[0] ||
                  "Failed to send message",
                icon: "info-circle",
                timeout: 3000
              },
              { root: true }
            );
            reject(err);
          });
      });
    }
  },
  mutations: {
    UPDATE_LOCAL_CONVERSATION(state, payload) {
      const data = {
        created_at: new Date().toISOString(),
        external_id: "",
        file: payload.file,
        id: payload.id,
        is_campaign: false,
        organization_id: "",
        participant_id: "",
        participant_type: "customer",
        reply: null,
        room: {},
        room_id: "",
        sender: {
          name: payload.user.name,
          avatar: {}
        },
        sender_id: payload.user.id,
        sender_type: "Models::User",
        status: "pending",
        text: payload.payload.text,
        type: payload.payload.type
      };

      state.conversations.push(data);
    },
    UPDATE_CONVERSATIONS(state, payload) {
      state.conversations = payload.reverse();
    },
    UPDATE_CONVERSATION_STATUS(state, params) {
      const arrayId = state.conversations.findIndex(
        data => data.id === params.id
      );
      state.conversations[arrayId].status = params.status;
    },
    PUSH_NOTIFICATION_TO_CONVERSATION(state, payload) {
      payload = payload.created_at ? payload : payload.data;
      const conv = {
        created_at: payload.created_at,
        external_id: "",
        file:
          payload.file && typeof payload.file === "string"
            ? JSON.parse(payload.file)
            : payload.file,
        id: payload.id,
        is_campaign: payload.is_campaign,
        organization_id: "",
        participant_id: payload.participant_id,
        participant_type: payload.participant_type,
        reply: null,
        room:
          payload.room && typeof payload.room === "string"
            ? JSON.parse(payload.room)
            : null,
        room_id: payload.room_id,
        sender:
          payload.sender && typeof payload.sender === "string"
            ? JSON.parse(payload.sender)
            : payload.sender,
        sender_id: payload.sender_id,
        sender_type: payload.sender_type,
        status: payload.status,
        text: payload.text,
        type: payload.type,
        url: payload.url
      };
      const mergedConv = [...state.conversations, conv];
      // unique by conv id
      state.conversations = [
        ...new Map(mergedConv.map(conv => [conv.id, conv])).values()
      ];
    }
  },
  modules: {}
};
