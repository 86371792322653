export const convertDate = () => {
  let text = new Date().toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  });
  text.replace(/\D+/g, "");
  return text;
};
