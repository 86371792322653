<template>
  <div v-if="isValidated">
    <Launcher @onClicked="launcherOnClick" />
  </div>
</template>

<script>
const Launcher = () => import("./components/Launcher.vue");
// import client from "../src/plugins/ablyInit";
import { mapState } from "vuex";
import * as Ably from "ably";

import commonService from "@/common/services";

export default {
  name: "App",
  components: {
    Launcher
  },

  data() {
    return {
      isInitiated: 0
    };
  },

  computed: {
    ...mapState("messenger", ["isValidated", "user"])
  },

  watch: {
    user() {
      if (this.user) this.initAbly();
    }
  },

  beforeMount() {
    // this.initAbly();
    const qchatCode = this.getParameterByName("c");
    const qchatId = this.getParameterByName("i");
    const qchatDomain = this.getParameterByName("d");
    const qchatUser = this.getParameterByName("u");

    if (qchatCode && qchatId) {
      localStorage.setItem("qchat_code", qchatCode);
      localStorage.setItem("qchat_id", qchatId);
      localStorage.setItem("qchat_domain", qchatDomain);
      if (qchatUser !== "null" || typeof qchatUser === "object") {
        localStorage.setItem("qchat_user", qchatUser);
      }
    }
    this.validateWebchat();
    if (!localStorage.getItem("qchat_ext_id")) this.generateExtID();
  },

  methods: {
    validateWebchat() {
      this.$store.dispatch("messenger/validateWebchat");
    },
    checkActiveUser() {
      this.$store.commit("messenger/DIRECT_SIGN_IN_UPDATE", true);
      const userData = JSON.parse(localStorage.getItem("qchat_user"));
      this.$store
        .dispatch("messenger/getConversation", {
          name: userData.name,
          email: userData.email,
          phone_number: userData.phone_number
        })
        .then(() => {
          this.showNotification(
            `Welcome, ${userData.email}!`,
            "info-circle",
            5000
          );
        })
        .catch(() => {
          this.$store.commit("messenger/DIRECT_SIGN_IN_UPDATE", false);
        });
    },
    async initAbly() {
      const webConfig = await commonService.config.get();
      const isEnableLoggingWebchat =
        webConfig.data.data.is_enable_logging_webchat;

      const client = new Ably.Realtime(
        process.env.VUE_APP_CONNECTION_KEY // Ably key
      );
      const qchat_user = localStorage.getItem("qchat_user")
        ? JSON.parse(localStorage.getItem("qchat_user"))
        : { token: null };
      client.connection.on("connected", () => {
        console.info("Connected!");
      });
      const channel = client.channels.get(localStorage.getItem("qchat_id")); // widget id
      channel.subscribe(qchat_user.token, message => {
        const notificationData = JSON.parse(message.data);
        if (isEnableLoggingWebchat) {
          console.log("notificationData", notificationData);
        }
        // user token
        if (notificationData.notification_type === "message") {
          this.$store.commit(
            "conversation/PUSH_NOTIFICATION_TO_CONVERSATION",
            notificationData.data
          );
        } else if (
          notificationData.notification_type === "updated_room_online"
        ) {
          this.$store.commit(
            "messenger/UPDATE_AGENT_STATUS",
            notificationData.body.is_participant_online
          );
          this.$store.commit("messenger/SHOW_NOTIFICATION", {
            text: notificationData.body.is_participant_online
              ? "Agent is online now"
              : "Agent is going offline",
            icon: notificationData.body.is_participant_online
              ? "user"
              : "user-slash",
            timeout: 5000
          });
        }
      });
    },
    launcherOnClick() {
      const qchatUser = JSON.parse(localStorage.getItem("qchat_user"));
      if (
        qchatUser &&
        qchatUser.widget_id === localStorage.getItem("qchat_id")
      ) {
        this.checkActiveUser();
      }
    },
    onTriggered() {
      // To trigger the "onPanelTrigger" when open or close webchat
      // window.addEventListener("message", function(e) {
      //   if (e.data.eventName === "onPanelTrigger")
      //     console.log(e.data.data.isOpened);
      // });
    },
    generateExtID() {
      const id = localStorage.getItem("qchat_id");
      const code = localStorage.getItem("qchat_code");
      const date = new Date();
      const extID = btoa(`${id}_${code}_${date.getTime()}`);
      localStorage.setItem("qchat_ext_id", extID);
    }
  }
};
</script>

<style lang="scss">
.qchat__full-screen {
  .qchat-messenger {
    width: 100%;
    height: 100%;
    bottom: 0px;
    right: 0px;
    border-radius: 0;
    box-shadow: none;
  }
  .qchat-button {
    bottom: 20px;
    right: 20px;
  }
  .qchat-conv {
    height: calc(100vh - 150px);
  }
  .qchat-conversation {
    height: calc(100vh - 110px);
  }
  .qchat-messenger__heading {
    border-radius: 0;
  }
}

.qchat__full-screen-sm {
  .qchat-messenger {
    width: 100%;
    height: 100%;
    bottom: 0px;
    right: 0px;
    border-radius: 0;
    box-shadow: none;
  }
  .qchat-conv {
    height: calc(100vh - 160px);
  }
  .qchat-conversation {
    height: calc(100vh - 120px);
  }
  .qchat-messenger__heading {
    border-radius: 0;
  }
}
</style>
