import axios from "axios";
import resource from "@/api/resource";

const AxiosCancelToken = axios.CancelToken;
const NAMESPACE = "CONFIG";

export default {
  /**
   * @description Get web config
   * @returns {Promise}
   */
  get() {
    let cancel;
    const CANCEL_TOKEN = `${NAMESPACE}_GET`;
    const request = resource.get("/api/core/v1/client_configs/config", {
      cancelToken: new AxiosCancelToken(cancelRequest => {
        cancel = cancelRequest;
      })
    });

    window[CANCEL_TOKEN] = cancel;

    return request;
  }
};
