import _ from "lodash";

export default {
  methods: {
    bracketClear(message) {
      if (message) message = message.replaceAll("<", "< ");
      return this.whatsappToPreviewFormatter(message);
    },
    contentFormatter(message) {
      if (message) {
        let text = this.isRead ? message : this.sliceWords(message, 256);
        let indexingText = this.isRead
          ? message
          : this.sliceWords(message, 256);
        let currentPoint = 0;
        if (text) {
          let bunchOfUrls = [];
          const outputResponse = [];
          const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

          text.replace(urlRegex, url => {
            // Check if there is currency
            const isUsingCurrency = url[0] + url[1] === "Rp";
            let extractAfterCurrency = url;
            if (isUsingCurrency) extractAfterCurrency = url.split("Rp")[1];
            if (!parseInt(extractAfterCurrency)) {
              const index = [];
              index.push(indexingText.indexOf(url));
              index.forEach(idx => {
                const isUsingProtocol =
                  indexingText[idx - 2] === "/" &&
                  indexingText[idx - 1] === "/";
                const protocol =
                  indexingText[idx - 4] === "s" ? "https" : "http";
                // eslint-disable-next-line no-useless-escape
                // url = url.replace(/[,+()$~'"*<>{}]/g, '') // url punctuation
                bunchOfUrls.push({
                  link: isUsingProtocol ? `${protocol}://${url}` : url,
                  index: isUsingProtocol
                    ? currentPoint + idx + (protocol.length + 3)
                    : currentPoint + idx
                });
                indexingText = indexingText.replace(
                  isUsingProtocol ? `${protocol}://${url}` : url,
                  ""
                );
                currentPoint =
                  (isUsingProtocol
                    ? currentPoint + idx + (protocol.length + 3)
                    : currentPoint + idx) + url.length;
              });
            }
          });

          bunchOfUrls = _.sortBy(bunchOfUrls, url => url.index); // sort by index
          bunchOfUrls = _.uniqBy(bunchOfUrls, "index"); // unique by index

          bunchOfUrls.forEach((url, index) => {
            try {
              text = text.replace(url.link, `#~${index}`); // make sure every url.link is unique
              const textBefore = text.split(`#~${index}`)[0];
              const textAfter =
                index + 1 < bunchOfUrls.length
                  ? ""
                  : text.split(`#~${index}`)[1];
              outputResponse.push({
                url: url.link,
                textBefore,
                textAfter
              });
              text = text.split(`#~${index}`)[1];
            } catch (err) {
              return bunchOfUrls.length > 0
                ? outputResponse
                : [
                    {
                      url: "",
                      textBefore: this.isRead
                        ? this.whatsappToPreviewFormatter(message, true)
                        : this.sliceWords(
                            this.whatsappToPreviewFormatter(message, true),
                            256
                          ),
                      textAfter: ""
                    }
                  ];
            }
          });

          return bunchOfUrls.length > 0
            ? outputResponse
            : [
                {
                  url: "",
                  textBefore: this.isRead
                    ? this.whatsappToPreviewFormatter(message, true)
                    : this.sliceWords(
                        this.whatsappToPreviewFormatter(message, true),
                        256
                      ),
                  textAfter: ""
                }
              ];
        } else
          return this.isRead
            ? this.whatsappToPreviewFormatter(message, true)
            : this.sliceWords(
                this.whatsappToPreviewFormatter(message, true),
                256
              );
      } else return "";
    },
    sliceWords(text, length) {
      if (text) {
        if (text.length > length) return text.substring(0, length) + "...";
        else return text;
      } else {
        return text;
      }
    },
    whatsappFormatter(text) {
      if (text) {
        let newText = text;
        newText = newText.replace(/<b>(.*?)<\/b>/g, "*$1*");
        newText = newText.replace(/<i>(.*?)<\/i>/g, "_$1_");
        newText = newText.replace(/<strike>(.*?)<\/strike>/g, "~$1~");
        newText = newText.replace(new RegExp("<br />", "g"), "\n");
        return newText;
      }
    },
    whatsappToPreviewFormatter(text) {
      if (text) {
        let newText = text;
        newText = newText.replace(/\*(.*?)\*/g, "<b>$1</b>");
        newText = newText.replace(/_(.*?)_/g, "<i>$1</i>");
        newText = newText.replace(/~(.*?)~/g, "<strike>$1</strike>");
        // newText = newText.replace(/\n/g, '<br />')

        // check URL, no need for now
        // newText = this.hubConversationLinkFormatter(newText);

        return newText;
      } else {
        return "";
      }
    },
    showNotification(text, icon, timeout) {
      this.$store.commit("messenger/SHOW_NOTIFICATION", {
        text,
        icon,
        timeout
      });
    },
    scrollConversationToBottom() {
      setTimeout(() => {
        const objDiv = document.getElementById("conversations");
        objDiv.scrollTop = objDiv.scrollHeight;
      }, 200);
    },
    getParameterByName(name, url = window.location.href) {
      name = name.replace(/[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    reformatUrl(url) {
      let redirectUrl = url.includes("http") ? url : "https://" + url;
      redirectUrl = redirectUrl.replace(/<b>(.*?)<\/b>/g, "$1");
      redirectUrl = redirectUrl.replace(/<i>(.*?)<\/i>/g, "$1");
      redirectUrl = redirectUrl.replace(/<strike>(.*?)<\/strike>/g, "$1");
      redirectUrl = redirectUrl.replace(/(.*?)<\/b>/g, "$1");
      redirectUrl = redirectUrl.replace(/(.*?)<\/i>/g, "$1");
      redirectUrl = redirectUrl.replace(/(.*?)<\/strike>/g, "$1");
      redirectUrl = redirectUrl.replace(new RegExp("<br />", "g"), "\n");

      return (
        '<a href="' +
        redirectUrl.replace(/(.*?)<\//g, "$1") +
        '" style="word-break: break-all;" target="_blank">' +
        this.whatsappFormatter(url) +
        "</a>"
      );
    },
    hubConversationLinkFormatter(text) {
      let urlRegex;
      if (text.includes("//")) {
        urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
      } else
        urlRegex = /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi;

      return text.replace(urlRegex, url => this.reformatUrl(url));
    }
  }
};
